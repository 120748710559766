define("bottomline-execution/controllers/shifts/index", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "@ember/utils", "bottomline-execution/mixins/app-state", "bottomline-execution/mixins/map-element-data"], function (_exports, _controller, _object, _computed, _service, _utils, _appState, _mapElementData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_appState.default, _mapElementData.default, {
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    normalScreenSize: (0, _computed.not)('truckSettings.small_screen_ui'),
    showGeographicalMapPanel: (0, _computed.alias)('truckSettings.shifts_panel_geographical_map'),
    showTripStopsPanel: (0, _computed.alias)('truckSettings.shifts_panel_trip_stops'),
    showRemarksPanel: (0, _computed.alias)('truckSettings.shifts_panel_remarks'),
    selectedTrip: (0, _computed.alias)('appState.selectedTrip'),
    selectedShift: (0, _computed.alias)('appState.selectedShift'),
    tripsOnTheMap: (0, _object.computed)('selectedShift.tripStops.[]', 'selectedTrip.tripStops.[]', function () {
      var _this = this;

      var tripStops = this.get('selectedShift.tripStops');
      if ((0, _utils.isEmpty)(tripStops)) return [];
      return tripStops.reduce(function (collection, tripStop) {
        var selectedTripStops = _this.get('selectedTrip.tripStops');

        var isSelected = (selectedTripStops || []).includes(tripStop);

        var tripStopOnMap = _this.getTripStopOnMapInfo(tripStop, isSelected);

        collection.pushObject(tripStopOnMap);

        var tanksOnMap = _this.getTripStopTanksMapInfo(tripStop, isSelected);

        collection.pushObjects(tanksOnMap);
        return collection;
      }, []);
    }),
    iconSetForDefaultZoomGeoMap: (0, _object.computed)('tripsOnTheMap.[]', function () {
      var tripsOnTheMap = this.get('tripsOnTheMap');
      var unloadStopsOnTheMap = tripsOnTheMap.filter(function (trip) {
        return trip.get('tripStop.isUnload');
      });

      if ((0, _utils.isPresent)(unloadStopsOnTheMap)) {
        return unloadStopsOnTheMap;
      } else {
        return tripsOnTheMap;
      }
    }),
    includeCurrentLocationInZoomGeoMap: (0, _object.computed)('tripsOnTheMap.[]', function () {
      var tripsOnTheMap = this.get('tripsOnTheMap');
      var unloadStopsOnTheMap = tripsOnTheMap.filter(function (trip) {
        return trip.get('tripStop.isUnload');
      });
      return (0, _utils.isEmpty)(unloadStopsOnTheMap);
    }),
    actions: {
      navigateToTripPage: function navigateToTripPage(shift) {
        return this.router.transitionTo('trips.index', shift.id);
      },
      onShiftTripNavigateClick: function onShiftTripNavigateClick(trip) {
        trip.set('isShown', true);
        this.router.transitionTo('trips.index', trip.get('shift.id'));
      },
      setSelectedTripId: function setSelectedTripId(tripId) {
        this.get('appState').setSelectedTripId(tripId);
      },
      setSelectedTripFromTripStop: function setSelectedTripFromTripStop(tripStopId) {
        var tripId = this.get('store').peekRecord('trip-stop', tripStopId).get('TripID');
        this.send('setSelectedTripId', tripId);
      }
    }
  });

  _exports.default = _default;
});