define("bottomline-execution/components/signatures", ["exports", "@ember/object/computed", "@ember/object", "@ember/utils", "@ember/component", "bottomline-execution/mixins/app-state", "@ember/service"], function (_exports, _computed, _object, _utils, _component, _appState, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_appState.default, {
    tagName: 'div',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('cordovaPlatform.isCordova')) {
        this._addTouchstartEventListeners();
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('cordovaPlatform.isCordova')) {
        this._removeTouchstartEventListeners();
      }

      this._resetCustomerSignature();

      this._resetDriverSignature();

      this._super.apply(this, arguments);
    },
    cordovaPlatform: (0, _service.inject)('ember-cordova/platform'),
    truckSettings: (0, _computed.alias)('appState.truckSettings'),
    requireDriverSignature: null,
    customerSignatureImage: (0, _object.computed)('customerSignature.[]', {
      get: function get() {
        if ((0, _utils.isEmpty)(this.get('customerSignature'))) return '';
        return this._signatureImage('customer_signature');
      },
      set: function set(_propertyName, value) {
        return value;
      }
    }),
    driverSignatureImage: (0, _object.computed)('driverSignature.[]', {
      get: function get() {
        if ((0, _utils.isEmpty)(this.get('driverSignature'))) return '';
        return this._signatureImage('driver_signature');
      },
      set: function set(_propertyName, value) {
        return value;
      }
    }),
    _addTouchstartEventListeners: function _addTouchstartEventListeners() {
      this.element.addEventListener('touchstart', this._hideVirtualKeyboard, false);

      this._getSignatureCanvas('customer_signature').addEventListener('touchstart', this._hideVirtualKeyboard, false);

      this._getSignatureCanvas('driver_signature').addEventListener('touchstart', this._hideVirtualKeyboard, false);
    },
    _removeTouchstartEventListeners: function _removeTouchstartEventListeners() {
      this.element.removeEventListener('touchstart', this._hideVirtualKeyboard);

      this._getSignatureCanvas('customer_signature').removeEventListener('touchstart', this._hideVirtualKeyboard);

      this._getSignatureCanvas('driver_signature').removeEventListener('touchstart', this._hideVirtualKeyboard);
    },
    _hideVirtualKeyboard: function _hideVirtualKeyboard() {
      var activeElement = document.activeElement;
      if (!activeElement.matches('input, textarea')) return;
      activeElement.blur();
    },
    _getSignatureCanvas: function _getSignatureCanvas(signatureId) {
      return this.element.querySelector("div#".concat(signatureId, " > canvas"));
    },
    _resetCustomerSignature: function _resetCustomerSignature() {
      this.set('customerSignature', []);
    },
    _resetDriverSignature: function _resetDriverSignature() {
      this.set('driverSignature', []);
    },
    actions: {
      resetCustomerSignature: function resetCustomerSignature() {
        this._resetCustomerSignature();
      },
      resetDriverSignature: function resetDriverSignature() {
        this._resetDriverSignature();
      }
    },
    _signatureImage: function _signatureImage(signatureId) {
      var svg = this._getSignatureCanvas(signatureId);

      if (svg) {
        // Use console.log(svg.toDataURL("image/png")) and copy-paste the URL to inspect the image
        return svg.toDataURL("image/png");
      }
    },
    customerSignature: (0, _object.computed)(function () {
      return [];
    }),
    driverSignature: (0, _object.computed)(function () {
      return [];
    })
  });

  _exports.default = _default;
});