define("bottomline-execution/models/bx/trip-stop-truck-storage", ["exports", "bottomline-execution/models/trip-stop-truck-storage", "@ember/object", "@ember/utils", "ember-cp-validations", "@ember/service", "ember-data", "bottomline-execution/mixins/trip-stop-type"], function (_exports, _tripStopTruckStorage, _object, _utils, _emberCpValidations, _service, _emberData, _tripStopType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    MaterialID: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    StorageID: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    TruckID: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    TruckStorageID: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    Volume: {
      validators: [(0, _emberCpValidations.validator)('inline', {
        validate: function validate(_value, _options, model) {
          return (0, _utils.isPresent)(model.get('confirmedQty')) || (0, _utils.isPresent)(model.get('confirmed15Qty'));
        },
        dependentKeys: ['model.confirmedQty', 'model.confirmed15Qty']
      })]
    }
  });

  var _default = _tripStopTruckStorage.default.extend(Validations, _tripStopType.default, {
    store: (0, _service.inject)(),
    ManualOrderID: attr('string'),
    isVolatileRemoveableRecord: (0, _object.computed)('isLocalRecord', function () {
      if (this.get('trip_stop.isStartInventory')) {
        return false;
      } else {
        return this._super.apply(this, arguments);
      }
    }),
    trip_stop: (0, _object.computed)('TripStopID', function () {
      if (this.get('TripStopID')) {
        return this.get('store').peekRecord(this.tripStopType(), this.get('TripStopID'));
      }
    }),
    linkTripStopStorage: function linkTripStopStorage(tripStopStorage) {
      this.setProperties({
        StorageID: tripStopStorage.get('StorageID'),
        TripStopStorageID: tripStopStorage.get('id')
      });

      if (this.get('isLoad')) {
        this.set('MaterialID', tripStopStorage.get('MaterialID'));
      }
    }
  });

  _exports.default = _default;
});